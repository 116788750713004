<template>
  <div class="container">
    <img 
      :class="['img', (index === imgs.length - 1 ? 'fixed' : '')]" 
      v-for="(img, index) in imgs" 
      :key="index"
      :src="img" 
      alt=""
      @click="handleToCumenMP(index)"
    >

    <img 
      class="rules" 
      src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018ab69b-bb8b-d99b-e174-7b9df5ecd8b8?x-oss-process=style/origin" 
      alt=""
      @click="showRulesBox = true">

    <div v-show="showRulesBox" class="rules-box">
      <img class="rule" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018ab6b4-1f36-57bb-3826-050aafb3f06a?x-oss-process=style/origin" alt="">
      <div class="close" @click="showRulesBox = false"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        'https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018ab696-6dde-019a-984c-36eaadda94ff?x-oss-process=style/origin',
        'https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018ab696-6dd2-4047-8eb1-cb308add9243?x-oss-process=style/origin',
        'https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018ab696-6dbe-ffe1-71dc-eeb649f3485b?x-oss-process=style/origin',
        'https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018ab696-6dbe-140b-e065-07bffe91ee52?x-oss-process=style/origin',
      ],
      showRulesBox: false,
    }
  },
  methods: {
    handleToCumenMP(index) {
      const { imgs } = this;
      if(index === imgs.length - 1) window.location.href = 'weixin://dl/business/?t=gbOtASRkR0o'; 
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
$multipleCount: 1;

.img {
  display: block;
  width: 100%;
}

.fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.rules {
  position: fixed;
  right: 0;
  top: px2rem(228);
  width: px2rem(56);
  height: px2rem(80);
}

.rules-box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: px2rem(1308);
  z-index: 3;

  > .rule {
    width: 100%;
  }

  > .close {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: px2rem(138);
  }
}
</style>
